import React from 'react'
import { ReactElement } from 'react'
import { Link } from 'gatsby'
import loadable from '@loadable/component'
import { isPL } from '../util/langGrabber'

const Layout = loadable(
  () => import('@rocketseat/gatsby-theme-docs/src/components/Layout'),
)
const SEO = loadable(
  () => import('@rocketseat/gatsby-theme-docs/src/components/SEO'),
)

let title: string,
  content1: string,
  content2: string,
  mainLink: string,
  linkText: string
if (isPL()) {
  title = 'Nie odnaleziono strony'
  content1 =
    'Strona której szukasz nie została znaleziona albo jest niedostępna'
  content2 = 'Jeżeli chcesz wrócić do strony głownej, '
  mainLink = '/pl'
  linkText = 'wejdź tutaj.'
} else {
  title = 'Page not found'
  content1 = 'The page you are looking for was not found or is not available'
  content2 = 'To go back to the main page, '
  mainLink = '/en'
  linkText = 'go here.'
}

export default function NotFound(): ReactElement {
  return (
    <Layout title={title}>
      <SEO title="404: Not found" />
      <p>{content1}</p>
      <p>
        {content2}
        <Link to={mainLink}>{linkText}</Link>.
      </p>
    </Layout>
  )
}
